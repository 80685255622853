import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader";
import { JewishUSelectors } from "../../../../../state";
import RewardCard from "./RewardCard";
import RewardRequestModal from "./RewardRequestModal";
import RewardRequestCongratsModal from "./RewardRequestCongratsModal";
import StudentRewardCard from "./StudentRewardCard";
import Pages from "../../../../../pages";
import { PlatinumTripsCredits, TripRewardType } from "../../../CreditsConsts";
import {
  handleErrorMessage,
  isMobileView,
  isTabletView,
  pluralizeText,
  PageLink,
} from "../../../../../lib";
import moment from "moment";
const { REACT_APP_COCI_STUDENT_PORTAL_DOMAIN } = process.env;

const RewardTripTypeImage = {
  GrandTrip: "/images/JU_GrandTrip.png",
  PlatinumTrip: "/images/JU_PlatinumTrip.png",
};

const getDefaultTripImage = (tripCredits) => {
  switch (tripCredits) {
    case PlatinumTripsCredits:
      return RewardTripTypeImage.PlatinumTrip;
    default:
      return RewardTripTypeImage.GrandTrip;
  }
};

function Rewards({
  getRewards,
  getStudentRewards,
  refreshStudentDetails,
  requestReward,
  rewardRequest,
  rewards: _rewards,
  rewardTypes,
  student: { numOfCreditsAvailable },
  studentRewards: _studentRewards,
  currencyCode,
  isGrandTripPage,
  isLoggedIn,
}) {
  const [selectedRewardForRequest, setSelectedRewardForRequest] =
    useState(null);
  const [showRewardRequestModal, setShowRewardRequestModal] = useState(false);
  const [showRewardRequestCongratsModal, setShowRewardRequestCongratsModal] =
    useState(false);

  const eduStudentEnrollmentID = useSelector(
    JewishUSelectors.eduStudentEnrollmentID,
  );
  useEffect(() => {
    if (isLoggedIn) {
      getRewards();
      if (eduStudentEnrollmentID) getStudentRewards();
    }
  }, [getRewards, getStudentRewards, isLoggedIn, eduStudentEnrollmentID]);

  const getCategorizedRewards = useCallback(
    (rewards) => {
      const categorizedRewards = {};
      rewards
        .filter((reward) => reward.type !== TripRewardType)
        .forEach((reward) => {
          const typeDisplay = reward.typeDisplay || "Other";
          if (!categorizedRewards.hasOwnProperty(typeDisplay)) {
            categorizedRewards[typeDisplay] = {
              rewards: [],
              // get value for sorting purposes
              value:
                rewardTypes?.find((r) => r.displayValue === typeDisplay)
                  ?.value || 0,
            };
          }
          categorizedRewards[typeDisplay].rewards.push(reward);
        });
      return categorizedRewards;
    },
    [rewardTypes],
  );

  const toggleRequestReward = useCallback(
    (reward = null, showCongrats = false) => {
      setSelectedRewardForRequest(reward);
      setShowRewardRequestModal(!!reward);
      setShowRewardRequestCongratsModal(showCongrats);
    },
    [],
  );

  const getTripRewardDisplay = useCallback(
    (reward) => {
      const {
        creditsRestrictedFromDate,
        name,
        shortDescription,
        tripEvent,
        tripType,
        typeDisplay,
      } = reward;
      const {
        isTripRegistrationOpen,
        numOfStudentCreditsQualifyingForTrip,
        tripEndDate,
        tripPriceInCredits,
        tripProgramScheduleID,
        tripPromotionalPriceInCredits,
        tripStartDate,
        tripRegistrationEndDate,
        tripRegistrationStartDate,
      } = tripEvent || {};

      const isTripRegistrationAvailable = isLoggedIn && isTripRegistrationOpen;

      const isMobileOrTablet = isMobileView() || isTabletView();
      const minTripPriceInCredits =
        tripPromotionalPriceInCredits || tripPriceInCredits;
      const numOfCreditsAvailableForReward = creditsRestrictedFromDate
        ? numOfStudentCreditsQualifyingForTrip
        : numOfCreditsAvailable;
      const canRedeemTrip =
        numOfCreditsAvailableForReward >= minTripPriceInCredits;

      // redirect to trip application in student portal
      const tripApplicationUrl =
        `${REACT_APP_COCI_STUDENT_PORTAL_DOMAIN}trips/${tripType}` +
        (tripProgramScheduleID ? `/${tripProgramScheduleID}` : "");

      return (
        <>
          <div className="trip-reward-type">
            {tripPriceInCredits === PlatinumTripsCredits
              ? "Platinum trip"
              : typeDisplay}
          </div>
          <h3 className="trip-reward-title">{name}</h3>
          {!!(tripStartDate && tripEndDate) && (
            <p className="trip-reward-dates">
              {moment(tripStartDate).format("MMMM Do")}
              {" - "}
              {moment(tripEndDate).format("MMMM Do")}
            </p>
          )}
          {!!(tripRegistrationStartDate && tripRegistrationEndDate) && (
            <p className="trip-reward-registration-dates">
              Registration Dates
              <br />
              {moment(tripRegistrationStartDate).format("MMMM Do h:mm a")} ET
              {" - "}
              {moment(tripRegistrationEndDate).format("MMMM Do h:mm a")} ET
            </p>
          )}
          <p className="trip-reward-description">{shortDescription}</p>
          {!!tripPriceInCredits && (
            <p className="trip-reward-credit">
              {isMobileOrTablet && "Cost: "}
              {tripPromotionalPriceInCredits ? (
                <>
                  <img
                    src={`/images/JU_Strikethrough_${
                      isMobileOrTablet ? "red" : "white"
                    }.svg`}
                    alt="jewish learning institute"
                    height={isMobileOrTablet ? "15" : "16"}
                    style={{
                      marginRight: isMobileOrTablet ? -24 : -28,
                      marginBottom: isMobileOrTablet ? -3 : -2,
                      position: "relative",
                    }}
                  />{" "}
                  {tripPriceInCredits} {tripPromotionalPriceInCredits}*
                </>
              ) : (
                tripPriceInCredits
              )}{" "}
              {pluralizeText("Credit", tripPriceInCredits)}
            </p>
          )}
          <div className="flex">
            <PageLink
              to={Pages.grandTrip.tripDetails}
              params={{ id: reward.id }}
            >
              <button className="btn btn-accent btn-medium mt-24 mr-16">
                More Info
              </button>
            </PageLink>

            {isTripRegistrationAvailable && (
              <button
                className="btn btn-accent btn-medium mt-24"
                onClick={() =>
                  canRedeemTrip
                    ? (window.location = tripApplicationUrl)
                    : toggleRequestReward(reward)
                }
              >
                Redeem Now
              </button>
            )}
          </div>
        </>
      );
    },
    [isLoggedIn, toggleRequestReward, numOfCreditsAvailable],
  );

  const renderCategorizedRewards = useCallback(
    (rewards, currencyCode) => {
      const categorizedRewards = getCategorizedRewards(rewards);
      const tripRewards = rewards.filter(
        (reward) => reward.type === TripRewardType,
      );

      const areAllTripsInFuture = tripRewards.every(
        (trip) => !trip.hasTripStarted,
      );

      return (
        <>
          <div>
            <div className="top-section-header-container">
              <div className="title">Learn Lots and Live Large</div>
              <div className="sub-title">
                Frequent learning can increase your JewishU credits value by 4x,
                and give you access to one of our exclusive educational
                adventures.
              </div>

              <div className="cards-container">
                <div
                  className="card"
                  style={{
                    background: `url(${RewardTripTypeImage.GrandTrip})`,
                    backgroundSize: "cover",
                  }}
                >
                  <p className="card-title">Grand Trips</p>
                  <p className="card-text">
                    Accumulate{" "}
                    <img
                      src="/images/JU_Strikethrough_white.svg"
                      alt="jewish learning institute"
                      height="16"
                      style={{
                        marginRight: -28,
                        marginBottom: -2,
                        position: "relative",
                      }}
                    />{" "}
                    20 16* credits within one school year to earn one of our
                    daring American Adventures. Explore the offbeat sites, and
                    enjoy uncommon adrenaline-rushing activities.
                  </p>
                </div>
                <div
                  className="card"
                  style={{
                    background: `url(${RewardTripTypeImage.PlatinumTrip})`,
                    backgroundSize: "cover",
                  }}
                >
                  <p className="card-title">Platinum Trips</p>
                  <p className="card-text">
                    Or go even bigger and accumulate {PlatinumTripsCredits}{" "}
                    credits within one school year for a trip to an exotic
                    international location (e.g., Dubai, Morocco, or South
                    Africa).
                  </p>
                </div>
              </div>

              <div className="small-text">
                *Promotion only for summer 2024 and first-time JewishU trip
                goers. <br /> All trips subject to change, eligibility and
                availability. <br />
                Read the FAQ section below.{" "}
              </div>
            </div>

            <div className="trip-rewards-container">
              {tripRewards.length > 0 && (
                <div className="trip-rewards-header">
                  {areAllTripsInFuture ? "Upcoming Trips" : "Trips"}
                </div>
              )}
              {tripRewards.map((reward, i) => {
                const { tripPriceInCredits } = reward;

                return (
                  <div className="trip-reward-info" key={i}>
                    <img
                      alt="trip img"
                      src={
                        reward.tripEvent?.imageURL ||
                        getDefaultTripImage(tripPriceInCredits)
                      }
                    />
                    <div className="details">
                      {getTripRewardDisplay(reward)}
                    </div>

                    {!isMobileView() &&
                      !isTabletView() &&
                      i % 1 === 0 &&
                      i !== tripRewards.length - 1 && (
                        <div className="company-logo">
                          <img
                            src="/images/jewish-u-logo-transparent-large.svg"
                            alt="jewish u logo"
                          />
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
          {!isGrandTripPage && (
            <div className="container mt-80">
              <p className="fw-700 xxl-text line-height-double mb-24">
                Or redeem as many credits as you want, whenever you want, for
                whatever you want from the options below.
              </p>
              {Object.keys(categorizedRewards)
                .sort(
                  (ck1, ck2) =>
                    categorizedRewards[ck2].value -
                    categorizedRewards[ck1].value,
                )
                .map((categoryKey, categoryIndex) => (
                  <div className="credits-section mb-80" key={categoryIndex}>
                    <h4 className="large-text mb-24 fw-600">
                      {categoryKey} rewards
                    </h4>
                    <div className="rewards-cards-grid">
                      {categorizedRewards[categoryKey].rewards.map(
                        (reward, rewardIndex) => (
                          <RewardCard
                            key={rewardIndex}
                            onRequestReward={() => toggleRequestReward(reward)}
                            reward={reward}
                            currencyCode={
                              reward.tripType ? "USD" : currencyCode // rewards redeemed for trips always use USD currency
                            }
                          />
                        ),
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </>
      );
    },
    [
      getCategorizedRewards,
      isGrandTripPage,
      getTripRewardDisplay,
      toggleRequestReward,
    ],
  );

  const renderCashedOutRewards = useCallback(
    (studentRewards, studentRewardsError) => (
      <div className="credits-section container mb-80">
        <p className="fw-700 xxl-text line-height-double mb-24">
          My cashed out rewards
        </p>
        {studentRewardsError ? (
          <p className="error-text">
            {handleErrorMessage(
              studentRewardsError,
              "Something went wrong and we could not retrieve cashed out rewards.",
            )}
          </p>
        ) : studentRewards && studentRewards.length ? (
          <div className="rewards-cards-grid">
            {studentRewards.map((rewardRequest, rewardIndex) => (
              <StudentRewardCard
                key={rewardIndex}
                rewardRequest={rewardRequest}
              />
            ))}
          </div>
        ) : (
          <div>No cashed out rewards</div>
        )}
      </div>
    ),
    [],
  );

  const {
    data: rewards,
    error: rewardsError,
    loading: rewardsLoading,
  } = _rewards;
  const {
    data: studentRewards,
    error: studentRewardsError,
    loading: studentRewardsLoading,
  } = _studentRewards;

  return rewardsLoading || studentRewardsLoading ? (
    <div>
      <Loader />
    </div>
  ) : (
    <div>
      <div className="credits-section">
        {!rewards?.length && (
          <div className="container">
            <p className="credits-section-title xxl-text mb-24 fw-700">
              Rewards
            </p>
          </div>
        )}
        {rewardsError ? (
          <p className="container error-text">
            {handleErrorMessage(
              rewardsError,
              "Something went wrong and we could not retrieve program rewards.",
            )}
          </p>
        ) : rewards?.length ? (
          renderCategorizedRewards(rewards, currencyCode)
        ) : (
          <p className="container">No rewards available</p>
        )}
      </div>
      {!isGrandTripPage &&
        renderCashedOutRewards(studentRewards, studentRewardsError)}

      {showRewardRequestModal && (
        <RewardRequestModal
          close={(showCongrats) => toggleRequestReward(null, showCongrats)}
          numOfCreditsAvailable={
            selectedRewardForRequest?.creditsRestrictedFromDate
              ? selectedRewardForRequest.tripEvent
                  ?.numOfStudentCreditsQualifyingForTrip
              : numOfCreditsAvailable
          }
          requestReward={requestReward}
          reward={selectedRewardForRequest}
          rewardRequest={rewardRequest}
          currencyCode={
            selectedRewardForRequest?.tripType ? "USD" : currencyCode // rewards redeemed for trips always use USD currency
          }
        />
      )}

      <RewardRequestCongratsModal
        close={() => {
          setShowRewardRequestCongratsModal(false);
          //refresh reward & student credits info
          getRewards();
          getStudentRewards();
          refreshStudentDetails();
        }}
        show={showRewardRequestCongratsModal}
        studentName={
          showRewardRequestCongratsModal &&
          studentRewards?.length > 0 &&
          studentRewards[0].studentFirstName
        }
      />
    </div>
  );
}

export default memo(Rewards);
