import { CreditsActions } from "./actions";

export const CreditsState = {
  name: "credits",
  persist: false,
  defaults: {
    student: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    studentShluchimProfile: {},
    studentUpdate: {
      error: undefined,
      loading: false,
      success: undefined,
    },
    rewards: {
      data: [],
      error: undefined,
      loading: false,
      success: undefined,
    },
    studentRewards: {
      data: [],
      error: undefined,
      loading: false,
      success: undefined,
    },
    reward: {
      data: undefined,
      error: undefined,
      loading: false,
      success: undefined,
    },
    rewardRequest: {
      error: undefined,
      loading: false,
      success: undefined,
    },
  },
  handlers: {
    [CreditsActions.SET_GET_STUDENT_LOADING]: function (state, action) {
      return {
        ...state,
        student: {
          ...state.student,
          loading: !!action.payload,
        },
      };
    },
    [CreditsActions.GET_STUDENT_COMPLETE]: function (state, action) {
      return {
        ...state,
        student: {
          ...state.student,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CreditsActions.GET_STUDENT_ERROR]: function (state, action) {
      return {
        ...state,
        student: {
          ...state.student,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },

    [CreditsActions.SET_STUDENT_UPDATE_LOADING]: function (state, action) {
      return {
        ...state,
        studentUpdate: {
          ...state.studentUpdate,
          loading: !!action.payload,
        },
      };
    },
    [CreditsActions.STUDENT_UPDATE_COMPLETE]: function (state, action) {
      return {
        ...state,
        studentUpdate: {
          ...state.studentUpdate,
          loading: false,
          success: true,
          error: undefined,
        },
      };
    },
    [CreditsActions.GET_STUDENT_SHLUCHIM_PROFILE]: function (state, action) {
      return {
        ...state,
        studentShluchimProfile: action.payload,
      };
    },
    [CreditsActions.STUDENT_UPDATE_ERROR]: function (state, action) {
      return {
        ...state,
        studentUpdate: {
          ...state.studentUpdate,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    },

    [CreditsActions.SET_GET_REWARDS_LOADING]: function (state, action) {
      return {
        ...state,
        rewards: {
          ...state.rewards,
          loading: !!action.payload,
        },
      };
    },
    [CreditsActions.GET_REWARDS_COMPLETE]: function (state, action) {
      return {
        ...state,
        rewards: {
          ...state.rewards,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CreditsActions.GET_REWARDS_ERROR]: function (state, action) {
      return {
        ...state,
        rewards: {
          ...state.rewards,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },

    [CreditsActions.SET_GET_STUDENT_REWARDS_LOADING]: function (state, action) {
      return {
        ...state,
        studentRewards: {
          ...state.studentRewards,
          loading: !!action.payload,
        },
      };
    },
    [CreditsActions.GET_STUDENT_REWARDS_COMPLETE]: function (state, action) {
      return {
        ...state,
        studentRewards: {
          ...state.studentRewards,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CreditsActions.GET_STUDENT_REWARDS_ERROR]: function (state, action) {
      return {
        ...state,
        studentRewards: {
          ...state.studentRewards,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },

    [CreditsActions.SET_GET_REWARD_LOADING]: function (state, action) {
      return {
        ...state,
        reward: {
          ...state.reward,
          loading: !!action.payload,
        },
      };
    },
    [CreditsActions.GET_REWARD_COMPLETE]: function (state, action) {
      return {
        ...state,
        reward: {
          ...state.reward,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CreditsActions.GET_REWARD_ERROR]: function (state, action) {
      return {
        ...state,
        reward: {
          ...state.reward,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [CreditsActions.SET_REWARD_REQUEST_LOADING]: function (state, action) {
      return {
        ...state,
        rewardRequest: {
          ...state.rewardRequest,
          loading: !!action.payload,
        },
      };
    },
    [CreditsActions.REWARD_REQUEST_COMPLETE]: function (state, action) {
      return {
        ...state,
        rewardRequest: {
          ...state.rewardRequest,
          loading: false,
          success: true,
          error: undefined,
        },
      };
    },
    [CreditsActions.CLEAR_REWARDS_REQUEST]: function (state, action) {
      return {
        ...state,
        rewardRequest: CreditsState.defaults.rewardRequest,
      };
    },
    [CreditsActions.REWARD_REQUEST_ERROR]: function (state, action) {
      return {
        ...state,
        rewardRequest: {
          ...state.rewardRequest,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    },
  },
};
