import React from "react";
import ThesisUpload from "./ThesisUpload";
import { formatNumber } from "../../../../lib";

export default class EarnCertificate extends React.PureComponent {
  state = {
    showSubmitThesisSuccessMessage: false,
  };

  submitThesis = async (thesisDocumentURL) => {
    const { eduStudentEnrollmentID, status, studentID } = this.props.student;
    const studentToSubmit = {
      id: eduStudentEnrollmentID,
      status,
      studentID,
      thesisDocumentURL,
    };
    await this.props.submitStudentUpdate(studentToSubmit);

    const { studentUpdate } = this.props;
    if (studentUpdate.success) {
      this.setState({ showSubmitThesisSuccessMessage: true });
    }

    return studentUpdate;
  };

  render() {
    const {
      numOfCoursesRequired,
      student: { numOfCoursesCompleted, thesisDocumentURL },
    } = this.props;
    const { showSubmitThesisSuccessMessage } = this.state;

    const numOfRequiredCoursesCompleted =
      numOfCoursesCompleted >= numOfCoursesRequired
        ? numOfCoursesRequired
        : numOfCoursesCompleted;
    const canSubmitThesis =
      numOfRequiredCoursesCompleted === numOfCoursesRequired;

    return (
      <div className="credits-section container mb-80">
        {showSubmitThesisSuccessMessage && (
          <p className="success-text mb-16">
            Thanks! We have received your thesis and will review it shortly. We
            will notify you when your certificate is granted.
          </p>
        )}
        <p className="credits-section-title xxl-text mb-24 fw-700">
          Earn your certificate
        </p>
        <div className="flex flex-align-center flex-justify-space certificate-course-numbers mb-40 mobile-hidden">
          {Array(numOfCoursesRequired)
            .fill()
            .map((_, i) => (
              <div
                className={`fw-600 medium-text text-center certificate-course-number relative ${
                  numOfRequiredCoursesCompleted > i ? "completed" : ""
                }`}
                key={i}
              >
                <span className="certificate-course-status-circle" />
                <p>Course {i + 1}</p>
              </div>
            ))}
          <div className="fw-600 medium-text text-center">
            <span
              className={`certificate-course-status-circle ${
                thesisDocumentURL ? "completed" : "empty"
              }`}
            />
            <p>Submit thesis</p>
          </div>
        </div>

        <div className="desktop-hidden tablet-hidden flex flex-align-center">
          <div
            className={`mobile-course-number-container progress-${numOfRequiredCoursesCompleted}`}
          >
            <span className="mobile-course-number" id="prec">
              {formatNumber(numOfRequiredCoursesCompleted)} of{" "}
              {formatNumber(numOfCoursesRequired)}
            </span>
          </div>
          <div>
            <p className="medium-text fw-600">
              {thesisDocumentURL
                ? "Submit thesis"
                : !numOfRequiredCoursesCompleted
                ? "Get Started"
                : `Course ${formatNumber(numOfRequiredCoursesCompleted)}`}
            </p>
            <p className="medium-text accent-text mt-8">
              {thesisDocumentURL
                ? "Congrats!"
                : numOfRequiredCoursesCompleted === numOfCoursesRequired
                ? "Next: Submit thesis"
                : `Next: Course ${formatNumber(
                    numOfRequiredCoursesCompleted + 1,
                  )}`}
            </p>
          </div>
        </div>
        <div>
          <ThesisUpload
            disabled={!canSubmitThesis}
            documentURL={thesisDocumentURL}
            submitThesis={this.submitThesis}
          />
          {canSubmitThesis ? (
            <p className="accent-text line-height-double">
              Congrats! You’ve completed{" "}
              {formatNumber(numOfRequiredCoursesCompleted)} courses
              {thesisDocumentURL
                ? " and uploaded your thesis."
                : ". Remember to submit your thesis for review as soon as you’re ready."}
            </p>
          ) : (
            <p className="accent-text medium-text line-height-double">
              Once you’ve completed all {formatNumber(numOfCoursesRequired)}{" "}
              courses you will be able to submit your thesis for review.
            </p>
          )}
        </div>
      </div>
    );
  }
}
