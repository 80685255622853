import React from "react";
import { formatNumber, pluralizeText } from "../../../../lib";
import moment from "moment";

export default class MyCreditsOverview extends React.PureComponent {
  getTimeEnrolledDisplay = (memberSinceDate) => {
    let monthsEnrolled = moment().diff(moment(memberSinceDate), "months");
    let yearsEnrolled = 0;

    if (!monthsEnrolled) {
      return "";
    }

    if (monthsEnrolled >= 12) {
      yearsEnrolled = Math.floor(monthsEnrolled / 12);
      monthsEnrolled = monthsEnrolled % 12;
    }

    let timeEnrolledDisplay = "";

    if (yearsEnrolled) {
      timeEnrolledDisplay += `${formatNumber(yearsEnrolled)} ${pluralizeText(
        "year",
        yearsEnrolled,
      )}${monthsEnrolled ? " + " : ""}`;
    }
    if (monthsEnrolled) {
      timeEnrolledDisplay += `${formatNumber(monthsEnrolled)} ${pluralizeText(
        "month",
        monthsEnrolled,
      )}`;
    }

    return timeEnrolledDisplay;
  };

  render() {
    const {
      memberSinceDate,
      numOfCreditsAvailable,
      numOfCreditsPendingRedemption,
      numOfCreditsReceived,
      numOfCreditsRedeemed,
      numOfCoursesCompleted,
    } = this.props;

    const timeEnrolled = this.getTimeEnrolledDisplay(memberSinceDate);

    return (
      <div className="credits-section container mb-80">
        <p className="credits-section-title xxl-text mb-24 fw-700">
          My credits
        </p>
        <div className="card credits-overview-card">
          <div className="total-credits">
            <p
              className="accent-text-secondary fw-600 text-center"
              style={{ fontSize: "80px" }}
            >
              {formatNumber(numOfCreditsReceived)}
            </p>
            <p className="medium-text fw-600 mt-8 text-center">Total Credits</p>
          </div>
          <div
            className={`credits-overview-grid${
              !timeEnrolled ? " hide-months" : ""
            }`}
          >
            <div className="tablet-flex mobile-flex flex-align-center">
              <img src="/images/icon-credits.svg" alt="" />
              <div>
                <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                  {formatNumber(numOfCreditsAvailable)}{" "}
                  {pluralizeText("credit", numOfCreditsAvailable)}
                </p>
                <p className="small-text accent-text uppercase-text fw-500 mt-8">
                  Available to use
                </p>
              </div>
            </div>
            <div
              className={`tablet-flex mobile-flex ${
                numOfCreditsPendingRedemption > 0
                  ? "flex-align-start"
                  : "flex-align-center"
              }`}
            >
              <img src="/images/icon-credits-red.svg" alt="" />
              <div>
                <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                  {formatNumber(
                    numOfCreditsRedeemed + numOfCreditsPendingRedemption,
                  )}{" "}
                  {pluralizeText(
                    "credit",
                    numOfCreditsRedeemed + numOfCreditsPendingRedemption,
                  )}
                </p>
                <p className="small-text accent-text uppercase-text fw-500 mt-8">
                  Cashed out
                </p>
                {numOfCreditsPendingRedemption > 0 && (
                  <p className="small-text accent-text uppercase-text fw-500 mt-8">
                    (Includes {formatNumber(numOfCreditsPendingRedemption)}{" "}
                    pending)
                  </p>
                )}
              </div>
            </div>
            <div className="tablet-flex mobile-flex flex-align-center">
              <img src="/images/icon-classes.svg" alt="" />
              <div>
                <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                  {formatNumber(numOfCoursesCompleted)}{" "}
                  {pluralizeText("course", numOfCoursesCompleted)}
                </p>
                <p className="small-text accent-text uppercase-text fw-500 mt-8">
                  Completed
                </p>
              </div>
            </div>
            {!!timeEnrolled && (
              <div className="tablet-flex mobile-flex flex-align-center">
                <img src="/images/icon-months.svg" alt="" />
                <div>
                  <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                    {timeEnrolled}
                  </p>
                  <p className="small-text accent-text uppercase-text fw-500 mt-8">
                    Enrolled in JewishU
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
