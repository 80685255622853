import React, { useCallback, useEffect, useMemo, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Modal from "../../../../../components/Modal";
import Select from "../../../../../components/form/Select";
import FadeOutMessage from "../../../../../components/FadeOutMessage";
import {
  getCurrencySymbol,
  formatNumber,
  pluralizeText,
  handleErrorMessage,
  isMobileView,
} from "../../../../../lib";
import { CreditsSelectors } from "../../../../../state/credits/selectors";
import { CreditsActions } from "../../../../../state";
import { TripRewardType } from "../../../CreditsConsts";

const { REACT_APP_COCI_STUDENT_PORTAL_DOMAIN } = process.env;

export default function RewardRequestModal(props) {
  const { close, requestReward, numOfCreditsAvailable, currencyCode, reward } =
    props;

  const {
    creditsRestrictedFromDate,
    creditValueUSD,
    creditValueGBP,
    creditValueCAD,
    maxCredits,
    id: rewardId,
    isPartialRedemptionAllowed,
    name,
    shortDescription,
    applicationLink,
    tripEvent,
    tripType,
    type,
  } = reward || {};
  const {
    tripPriceInCredits,
    tripPromotionalPriceInCredits,
    tripProgramScheduleID,
  } = tripEvent || {};

  const isTripReward = type === TripRewardType;
  const hasTripApplication = !!(tripProgramScheduleID || tripType);

  const dispatch = useDispatch();

  const rewardRequestResponse = useSelector(CreditsSelectors.rewardRequest);
  const studentShluchimProfiles = useSelector(
    CreditsSelectors.studentShluchimProfiles,
  );

  const [
    maxStudentCreditsAvailableToRedeem,
    setMaxStudentCreditsAvailableToRedeem,
  ] = useState("");
  const [creditsToRedeem, setCreditsToRedeem] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const rewardCreditValue = useMemo(() => {
    switch (currencyCode) {
      case "GBP":
        return creditValueGBP;
      case "CAD":
        return creditValueCAD;
      default:
        return creditValueUSD;
    }
  }, [creditValueCAD, creditValueGBP, creditValueUSD, currencyCode]);

  const setInitialCreditsToRedeem = useCallback(() => {
    if (tripProgramScheduleID) {
      const minTripPriceInCredits =
        tripPromotionalPriceInCredits || tripPriceInCredits;
      setCreditsToRedeem(
        numOfCreditsAvailable >= minTripPriceInCredits
          ? minTripPriceInCredits
          : 0,
      );
    } else {
      //max # of credits this student can redeem for this reward - maxCredits if he has that many avail, o/w however many he has avail
      const maxStudentCreditsAvailableToRedeem =
        maxCredits && numOfCreditsAvailable >= maxCredits
          ? maxCredits
          : numOfCreditsAvailable;

      //if partial redemption is allowed, creditsToRedeem can be set to whatever maxStudentCreditsAvailableToRedeem is
      //if only full redemption is allowed, if the maxStudentCreditsAvailableToRedeem does not meet maxCredits, creditsToRedeem must be 0 (the reward cannot be requested)
      setCreditsToRedeem(
        isPartialRedemptionAllowed ||
          maxStudentCreditsAvailableToRedeem === maxCredits
          ? maxStudentCreditsAvailableToRedeem
          : 0,
      );

      setMaxStudentCreditsAvailableToRedeem(maxStudentCreditsAvailableToRedeem);
    }
  }, [
    isPartialRedemptionAllowed,
    maxCredits,
    numOfCreditsAvailable,
    tripPromotionalPriceInCredits,
    tripPriceInCredits,
    tripProgramScheduleID,
  ]);

  useEffect(() => {
    setInitialCreditsToRedeem();
  }, [setInitialCreditsToRedeem]);

  useEffect(() => {
    if (
      applicationLink &&
      isTripReward &&
      Object.keys(studentShluchimProfiles).length === 0
    )
      dispatch(CreditsActions.getStudentShluchimProfiles());
  }, [applicationLink, dispatch, isTripReward, studentShluchimProfiles]);

  useEffect(() => {
    const { error, success } = rewardRequestResponse;
    if (success) {
      dispatch(CreditsActions.clearRewardsRequest());
      close(true);
    } else if (error) {
      setErrorMessage(handleErrorMessage(error));
    }
  }, [applicationLink, close, dispatch, rewardRequestResponse]);

  const onSubmitRequest = async () => {
    setSubmitting(true);
    if (tripType) {
      // redirect to trip application in student portal
      const tripApplicationUrl =
        `${REACT_APP_COCI_STUDENT_PORTAL_DOMAIN}trips/${tripType}` +
        (tripProgramScheduleID ? `/${tripProgramScheduleID}` : "");
      window.location = tripApplicationUrl;
      return;
    }

    if (errorMessage) {
      setErrorMessage("");
    }

    await requestReward({
      eduRewardID: rewardId,
      creditsToRedeem,
    });
    setSubmitting(false);
  };

  const redeemButton = () => {
    const text = hasTripApplication
      ? applicationLink
        ? "Redeem now and complete application"
        : "Complete application to redeem"
      : "Redeem now";

    const width = hasTripApplication || isMobileView() ? "100%" : 250;
    const fontSize = hasTripApplication && isMobileView() ? 12 : 14;

    return (
      <div>
        <button
          className="btn block"
          style={{ fontSize, fontFamily: "Graphik", width }}
          disabled={requestReward.loading || !creditsToRedeem || submitting}
          onClick={onSubmitRequest}
        >
          {requestReward.loading ? "Redeeming..." : text}
        </button>
        <FadeOutMessage
          message={errorMessage}
          onTimeout={() => setErrorMessage("")}
        />
      </div>
    );
  };

  const availableCreditsDescription = `You have ${formatNumber(
    numOfCreditsAvailable,
  )} available ${pluralizeText("credit", numOfCreditsAvailable)} to redeem`;

  const dateLimitRules =
    creditsRestrictedFromDate &&
    `This trip only accepts credits earned since ${moment(
      creditsRestrictedFromDate,
    ).format("M/D/YYYY")}.`;
  return (
    <Modal
      className={`reward-modal ${type && type.toLowerCase()}-reward`}
      show={true}
    >
      <div className="flex flex-align-start flex-justify-space mb-24">
        <div>
          <p className="uppercase-text small-text fw-600">Redeem for {name}</p>
          {shortDescription && (
            <p
              className="line-height-double mt-8"
              style={{ whiteSpace: "pre-wrap" }}
            >
              <Linkify
                componentDecorator={(href, text, key) => (
                  <a
                    className="link-text"
                    href={href}
                    key={key}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text}
                  </a>
                )}
              >
                {shortDescription}
              </Linkify>
            </p>
          )}
        </div>
        <i
          className="material-icons link-text-secondary"
          onClick={() => close()}
        >
          close
        </i>
      </div>
      {!tripProgramScheduleID && (
        <p className="fw-600 medium-text mb-16">
          {availableCreditsDescription}
        </p>
      )}
      {tripProgramScheduleID ? (
        <div className="line-height-double">
          Credits required:{" "}
          <span className="fw-600">{formatNumber(tripPriceInCredits)}</span>
          {tripPromotionalPriceInCredits ? (
            <>
              {` (or `}
              <span className="fw-600">
                {formatNumber(tripPromotionalPriceInCredits)}
              </span>
              {` for first-time JewishU trip goers)`}
            </>
          ) : (
            ""
          )}
          <p className="fw-600 medium-text mt-16 mb-16">
            {availableCreditsDescription}
          </p>
          {!creditsToRedeem && (
            <>
              {dateLimitRules && <p>{dateLimitRules}</p>}
              You do not have sufficient credits for this trip. Try again when
              you earn more or redeem for a different reward.
            </>
          )}
        </div>
      ) : isPartialRedemptionAllowed ? (
        <>
          <div className="line-height-double mb-16">
            {maxStudentCreditsAvailableToRedeem !== numOfCreditsAvailable && (
              <p>
                You may redeem up to{" "}
                {formatNumber(maxStudentCreditsAvailableToRedeem)}{" "}
                {pluralizeText("credit", maxStudentCreditsAvailableToRedeem)}{" "}
                for this reward.
              </p>
            )}
            {!tripType && (
              <p>How many credits would you like to redeem for {name}?</p>
            )}
          </div>
          {!tripType && (
            <Select
              disabled={
                !maxStudentCreditsAvailableToRedeem ||
                maxStudentCreditsAvailableToRedeem < 0
              }
              name="creditsToRedeem"
              onChange={(_, val) => setCreditsToRedeem(val)}
              options={
                maxStudentCreditsAvailableToRedeem
                  ? Array(maxStudentCreditsAvailableToRedeem)
                      .fill()
                      .map((_, i) => ({
                        label: `${formatNumber(i + 1)} ${pluralizeText(
                          "credit",
                          i + 1,
                        )}`,
                        value: i + 1,
                      }))
                  : []
              }
              placeholder="Select credits"
              searchable={true}
              style={{ maxWidth: "160px" }}
              value={creditsToRedeem}
            />
          )}
        </>
      ) : (
        <div className="line-height-double">
          {`${name || "This reward"} requires you to redeem ${formatNumber(
            maxCredits,
          )} ${pluralizeText("credit", maxCredits)}. `}
          {!creditsToRedeem
            ? `You do not have sufficient credits available to redeem this reward.`
            : !tripType &&
              `Would you like to redeem ${formatNumber(
                maxCredits,
              )} of your ${formatNumber(numOfCreditsAvailable)} ${pluralizeText(
                "credit",
                numOfCreditsAvailable,
              )} now?`}
        </div>
      )}
      <div className="reward-modal-footer flex flex-align-center flex-justify-space mobile-block mt-40">
        {!!creditsToRedeem &&
          (hasTripApplication ? (
            <div className="full-width">{redeemButton()}</div>
          ) : (
            <div className="flex flex-align-center flex-justify-space full-width mobile-block">
              <p className="mobile-mb-16">
                Your reward value is {getCurrencySymbol(currencyCode)}
                {formatNumber(creditsToRedeem * rewardCreditValue)}
              </p>
              {redeemButton()}
            </div>
          ))}
      </div>
    </Modal>
  );
}
