import {
  AuthRequest,
  removeAuthRequestToken,
  setAuthRequestToken,
  frontChatInit,
  setSentryUser,
  clearSentryUser,
  Navigation,
  PageURL,
  loadScript,
  logRocketIdentifyUser,
} from "../../lib";
import { ActivityActions } from "../activity/actions";
import { MyJewishUActions } from "../myJewishU/actions";
import { SysActions } from "../sys/actions";
import Pages from "../../pages";
import querySerializer from "query-string";

const authType = "Student";

// async function loadFacebookAuthAPI() {
//   const { FB } = window;
//   // if (!FB) {
//   //   throw new Error('Facebook API not found.');
//   // }
//   if (FB) {
//     return Promise.resolve(FB);
//   }
//   return new Promise((resolve) => {
//     FB.init({
//       appId      : '1264749310358391',
//       // status     : true,
//       // xfbml      : true,
//       // version    : 'v2.7' // or v2.6, v2.5, v2.4, v2.3
//     }).then(resolve);
//   });
// }

function navigateAfterSignin(navigationState, afterFallback) {
  Navigation.redirect(
    Navigation.query.after || afterFallback || "/",
    navigationState,
  );
}

function navigateAfterSignout(autoSignin) {
  let url = Pages.main.home.path;
  //if on home page - do not navigate anywhere (even if autosignin is true, because we want to give user chance to log out if he has an unauthorized user)
  if (Navigation.locationURL !== url) {
    //autoSignin will be true on navigateAfterSignout call in response to unauthorized server req - in that case redirect user to login
    //otherwise it is a navigateAfterSignout call after manual signout request - simply redirect to home (if not already on home page)
    if (autoSignin) {
      // WARNING: Make sure the page reloads with whatever navigation is done here.
      // Otherwise, the API request that discovered the signout will get an error.
      url =
        `${Pages.account.login.path}?after=` +
        encodeURIComponent(Navigation.locationURL);
      Navigation.load(url);
    } else {
      // Avoiding a page reload is actually what disabled Google auto signin?
      Navigation.go(url);
    }
  }
}

async function signinCompleted(
  dispatch,
  getState,
  payload,
  trackLogin,
  navigationState,
) {
  const { accountID, expiration, person, token, userEmail, userEmailHash } =
    payload;

  setAuthRequestToken(token, expiration);

  if (accountID) {
    setSentryUser(accountID, person, userEmail);
  }

  if (trackLogin) {
    dispatch(ActivityActions.trackLogin());
  }

  frontChatInit(userEmail, userEmailHash);

  await dispatch(SysActions.getSystemData(true)); //force refresh cached system settings on login
  await dispatch(MyJewishUActions.getMyJewishU());

  dispatch({ type: AuthActions.SIGNIN_COMPLETED, payload });

  logRocketIdentifyUser();

  const {
    myJewishU: { data: { eduChabadHouse } = {} },
  } = getState();
  navigateAfterSignin(
    navigationState,
    eduChabadHouse &&
      PageURL.to(
        //default navigation to My Location page
        Pages.locations.locationDetails,
        {
          locationId: eduChabadHouse.slug || eduChabadHouse.id,
        },
        null,
      ),
  );
}

async function disableGoogleIdentityAutoSelect() {
  // disable google identity auto-select upon signout or signin failure
  // see https://developers.google.com/identity/gsi/web/guides/automatic-sign-in-sign-out#sign-out
  await loadScript("https://accounts.google.com/gsi/client");
  const googleAccountId = window.google?.accounts?.id;
  if (googleAccountId) {
    googleAccountId.disableAutoSelect();
  }
}

// //TODO - test if any errors not catching here
// function signoutFacebook() {
//   return new Promise((resolve, reject) => {
//     if (window.FB && window.FB.getAccessToken()) {
//       window.FB.logout((response) => {
//         if (!response || response.status === 'connected') {
//           console.log("Facebook auth signout error", response);
//         } else {
//           console.log('Facebook auth signout successful', response);
//         }
//         resolve();
//       });
//     } else {
//       console.log("Facebook auth no signout");
//       resolve();
//     }
//   });
//   //TODO - how can disconnect account on bad login?
// }

export const AuthActions = {
  DO_BASIC_SIGNIN: "DO_BASIC_SIGNIN",
  DO_SOCIAL_SIGNIN: "DO_SOCIAL_SIGNIN",
  SIGNIN_COMPLETED: "SIGNIN_COMPLETED",
  SIGNIN_ERROR: "SIGNIN_ERROR",

  DO_SIGNOUT: "DO_SIGNOUT",

  DO_BASIC_REGISTRATION: "DO_BASIC_REGISTRATION",
  DO_SOCIAL_REGISTRATION: "DO_SOCIAL_REGISTRATION",
  REGISTRATION_COMPLETED: "REGISTRATION_COMPLETED",
  REGISTRATION_ERROR: "REGISTRATION_ERROR",

  DO_CONFIRM_REGISTRATION: "DO_CONFIRM_REGISTRATION",
  CONFIRM_REGISTRATION_COMPLETED: "CONFIRM_REGISTRATION_COMPLETED",
  CONFIRM_REGISTRATION_ERROR: "CONFIRM_REGISTRATION_ERROR",

  doBasicSignin(payload) {
    return async function doingBasicSignin(dispatch, getState) {
      dispatch({ type: AuthActions.DO_BASIC_SIGNIN, payload });

      const { email, password } = payload;
      const response = await AuthRequest.post("auth/login/basic", {
        authType,
        password: password,
        userName: email,
      }).catch((err) => {
        dispatch({ type: AuthActions.SIGNIN_ERROR, payload: err });
        return undefined;
      });
      if (!response) {
        return;
      }

      return signinCompleted(dispatch, getState, response.data, true);
    };
  },

  // doFacebookSignin(facebookUser) {
  //   const token = facebookUser.accessToken;

  //   return async function doingFacebookSignin(dispatch, getState) {
  //     dispatch({ type: AuthActions.DO_SOCIAL_SIGNIN, payload: { token } });

  //     const response = await AuthRequest.post('auth/login/facebook', {
  //       authType,
  //       token
  //     }).catch((err) => {
  //       signoutFacebook();
  //       dispatch({ type: AuthActions.SIGNIN_ERROR, payload: err });
  //       return undefined;
  //     });
  //     if (!response) {
  //       return;
  //     }

  //     return signinCompleted(dispatch, getState, response.data, true);
  //   };
  // },

  doGoogleSignin(token) {
    return async function doingGoogleSignin(dispatch, getState) {
      dispatch({ type: AuthActions.DO_SOCIAL_SIGNIN, payload: { token } });

      const response = await AuthRequest.post("auth/login/google", {
        authType,
        token
      }).catch(async (err) => {
        await disableGoogleIdentityAutoSelect();
        dispatch({ type: AuthActions.SIGNIN_ERROR, payload: err });
        return undefined;
      });
      if (!response) {
        return;
      }

      return signinCompleted(dispatch, getState, response.data, true);
    };
  },

  doSignout() {
    return async function doingSignout(dispatch) {
      await disableGoogleIdentityAutoSelect();
      // signoutFacebook();

      removeAuthRequestToken();
      clearSentryUser();

      navigateAfterSignout();

      frontChatInit();

      // dispatch action to reset redux state after navigating for signout to avoid null reference errors in state accessors
      dispatch({ type: AuthActions.DO_SIGNOUT });
    };
  },

  doBasicRegistration(payload) {
    return async function doingBasicRegistration(dispatch, getState) {
      dispatch({ type: AuthActions.DO_BASIC_REGISTRATION, payload });

      const { email, password, recaptcha, ...profile } = payload;
      const response = await AuthRequest.post("account/create/basic", {
        authType,
        clientPortal: "JewishU",
        clientRedirectQueryString: Navigation.query.after
          ? querySerializer.stringify({ after: Navigation.query.after })
          : null,
        email,
        // expectedRecaptchaAction: "register", //TODO - waiting for react-google-recaptcha to update to v3 and include actions implementation
        password,
        profile,
        recaptchaToken: recaptcha,
      }).catch((err) => {
        dispatch({ type: AuthActions.REGISTRATION_ERROR, payload: err });
        return undefined;
      });
      if (!response) {
        return;
      }

      dispatch({
        type: AuthActions.REGISTRATION_COMPLETED,
        payload: response.data.payload,
      });
    };
  },

  doConfirmRegistration(id, token, doSignin = true) {
    return async function doingConfirmRegistration(dispatch, getState) {
      dispatch({
        type: AuthActions.DO_CONFIRM_REGISTRATION,
        payload: { id, token },
      });

      const queryString = querySerializer.stringify({
        id,
        token,
      });
      const response = await AuthRequest.get(
        `System/Actions/NewAccountConfirmEmail?${queryString}`,
      ).catch((err) => {
        dispatch({
          type: AuthActions.CONFIRM_REGISTRATION_ERROR,
          payload: err,
        });
        return undefined;
      });

      if (!response) {
        return;
      }

      dispatch({ type: AuthActions.CONFIRM_REGISTRATION_COMPLETED });

      if (doSignin) {
        return signinCompleted(dispatch, getState, response.data, true); //automatic signin after account confirmation
      }
    };
  },

  // doFacebookRegistration(facebookUser) {
  //   const token = facebookUser.accessToken;

  //   return async function doingFacebookRegistration(dispatch, getState) {
  //     dispatch({ type: AuthActions.DO_SOCIAL_REGISTRATION, payload: { token } });

  //     const response = await AuthRequest.post('account/create/token', {
  //       accessToken: token,
  //       authProvider: "Facebook",
  //       authType,
  //       token,
  //       updateProfile: true
  //     }).catch((err) => {
  //       signoutFacebook();
  //       dispatch({ type: AuthActions.REGISTRATION_ERROR, payload: err });
  //       return undefined;
  //     });

  //     if (!response) {
  //       return;
  //     }

  //     dispatch({ type: AuthActions.REGISTRATION_COMPLETED });
  //     return signinCompleted(dispatch, getState, response.data.payload, true); //automatic signin after fb account creation
  //   };
  // },

  doGoogleRegistration(token, accessToken) {
    return async function doingGoogleRegistration(dispatch, getState) {
      dispatch({
        type: AuthActions.DO_SOCIAL_REGISTRATION,
        payload: { token },
      });

      const response = await AuthRequest.post("account/create/token", {
        accessToken,
        authProvider: "Google",
        authType,
        token,
        updateProfile: true
      }).catch(async (err) => {
        await disableGoogleIdentityAutoSelect();
        dispatch({ type: AuthActions.REGISTRATION_ERROR, payload: err });
        return undefined;
      });

      if (!response) {
        return;
      }

      dispatch({ type: AuthActions.REGISTRATION_COMPLETED });
      return signinCompleted(dispatch, getState, response.data.payload, true); //automatic signin after google account creation
    };
  },

  handleAuthFailure(err) {
    const navigationFailedRejection = new Promise((resolve, reject) => {
      // NOTE: This timer should never go off, but if it does, then whatever
      // API request is waiting for a result will get an error.
      setTimeout(reject, 5000, err);
    });
    return () => {
      //return error when request is an Auth (login/register) request
      if (
        Navigation.location.pathname === Pages.account.login.path ||
        Navigation.location.pathname === Pages.account.register.path
      ) {
        return Promise.reject(err);
      }

      navigateAfterSignout(true);
      return navigationFailedRejection;
    };
  },
};
