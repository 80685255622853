import React from "react";
import { Prompt } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import FadeOutMessage from "../../../../components/FadeOutMessage";
import FilePreviewModal from "../../../../components/FilePreviewModal";
import { SysActions } from "../../../../state";
import { handleErrorMessage } from "../../../../lib";

class ThesisUpload extends React.PureComponent {
  state = {
    docForUpload: null,
    errorMessage: "",
    loading: false,
    showFilePreviewModal: false,
    successMessage: "",
  };

  cancelUpload = () => {
    this.setState({
      docForUpload: null,
    });
  };

  selectDocument = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      this.setState({
        docForUpload: Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        errorMessage: "",
      });
    } else {
      this.setState({
        docForUpload: null,
        errorMessage: "File could not be uploaded. Please try again.",
      });
    }
  };

  //Limited file-type version (PDF only)
  // selectDocument = (acceptedFiles, rejectedFiles) => {
  //   const acceptedFileTypes = ["application/pdf"];
  //   const unsupportedFileTypeMessage =
  //     "Unsupported file type. Please upload a PDF file.";

  //   if (acceptedFiles.length) {
  //     const file = acceptedFiles[0];
  //     if (!acceptedFileTypes.some((fileType) => fileType === file.type)) {
  //       this.setState({
  //         docForUpload: Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         }),
  //         errorMessage: "",
  //       });
  //     } else {
  //       this.setState({
  //         docForUpload: null,
  //         errorMessage: unsupportedFileTypeMessage,
  //       });
  //     }
  //   } else {
  //     const rejectedFile = rejectedFiles && rejectedFiles[0];
  //     const errorMessage =
  //       rejectedFile &&
  //       !acceptedFileTypes.some((fileType) => fileType === rejectedFile.type) &&
  //       unsupportedFileTypeMessage;
  //     this.setState({
  //       docForUpload: null,
  //       errorMessage:
  //         errorMessage ||
  //         "File could not be uploaded. Please try again. Make sure you are uploading a PDF file.",
  //     });
  //   }
  // };

  uploadThesis = async (document) => {
    const documentURL = await this.props.actions.uploadFile(
      document,
      "jewish_u_student_thesis",
    );

    return documentURL;
  };

  uploadAndSubmitDocument = () => {
    const { docForUpload } = this.state;

    this.setState(
      {
        errorMessage: "",
        loading: true,
        successMessage: "",
      },
      async () => {
        let newState = { loading: false };
        try {
          const docUploadURL = await this.uploadThesis(docForUpload);
          const submitUploadResponse = await this.props.submitThesis(
            docUploadURL,
          );

          const { error } = submitUploadResponse;

          if (error) {
            newState.errorMessage = handleErrorMessage(error);
          } else {
            newState.docForUpload = null;
            newState.successMessage =
              "Thanks! We have received your thesis and will review it shortly. We will notify you when your certificate is granted.";
          }
        } catch (err) {
          newState.errorMessage =
            "Sorry, something went wrong and your thesis could not be uploaded.  Please try again.";
        }

        this.setState({ ...newState });
      },
    );
  };

  render() {
    const {
      documentURL,
      disabled,
      ui: { isPhone },
    } = this.props;
    const {
      docForUpload,
      errorMessage,
      loading,
      showFilePreviewModal,
      successMessage,
    } = this.state;

    return (
      <div className="mt-16 mb-16">
        <Prompt
          when={!!docForUpload}
          message="You have not submitted your thesis yet. Are you sure you want to leave this page?"
        />
        <div className="flex mobile-block">
          {(docForUpload || documentURL) && (
            <div className="flex flex-align-center mb-16 mr-40">
              <p
                className="flex flex-align-center link-text-secondary"
                onClick={() => this.setState({ showFilePreviewModal: true })}
              >
                <i
                  className="material-icons mr-8 accent-text-secondary"
                  style={{ fontSize: "32px" }}
                >
                  description
                </i>
                My Thesis
              </p>
              {docForUpload && (
                <i
                  className="material-icons accent-text ml-16"
                  onClick={this.cancelUpload}
                >
                  close
                </i>
              )}
            </div>
          )}

          <div className="mb-16">
            {!docForUpload ? (
              <Dropzone
                className="dropzone"
                disabled={disabled || loading}
                onDrop={this.selectDocument}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <button className="btn btn-accent" disabled={disabled}>
                      Upload {documentURL ? "New" : "My"} Thesis
                    </button>
                  </div>
                )}
              </Dropzone>
            ) : (
              <button
                className="btn btn-accent"
                disabled={loading}
                onClick={this.uploadAndSubmitDocument}
              >
                Submit {documentURL ? "New" : "My"} Thesis
              </button>
            )}
          </div>
        </div>
        {(errorMessage || successMessage) && (
          <FadeOutMessage
            className={successMessage ? "success-message" : ""}
            message={errorMessage || successMessage}
            onTimeout={() =>
              this.setState({
                errorMessage: "",
                successMessage: "",
              })
            }
          />
        )}

        {loading && <div className="accent-text">Uploading thesis...</div>}

        <FilePreviewModal
          close={() => this.setState({ showFilePreviewModal: false })}
          downloadName="my_jewishu_thesis"
          isPhone={isPhone}
          show={showFilePreviewModal}
          title="My Thesis Preview"
          type={docForUpload && docForUpload.type}
          url={docForUpload ? docForUpload.preview : documentURL}
        />
      </div>
    );
  }
}

export default connect(
  function mapState(state) {
    return {
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: {
        ...bindActionCreators(SysActions, dispatch),
      },
    };
  },
)(ThesisUpload);
