import React from "react";
import Modal from "./Modal";

export default class FilePreviewModal extends React.PureComponent {
  getFileType(type, url) {
    if (type && this.supportedFileTypes.indexOf(type) >= 0) {
      return type;
    }

    if (url) {
      if (url.indexOf(".pdf") > 0) {
        return "application/pdf";
      } else if (url.indexOf(".html") > 0) {
        return "text/html";
      } else if (url.indexOf(".png") > 0) {
        return "image/png";
      } else if (url.indexOf(".jpeg") > 0 || url.indexOf(".jpg") > 0) {
        return "image/jpeg";
      } else if (url.indexOf(".tif") > 0 || url.indexOf(".tiff") > 0) {
        return "image/tif";
      } else if (url.indexOf(".gif") > 0) {
        return "image/gif";
      } else if (url.indexOf(".bmp") > 0) {
        return "image/bmp";
      }
    }

    return "";
  }

  supportedFileTypes = [
    "application/pdf",
    "text/html",
    "image/png",
    "image/jpeg",
    "image/tif",
    "image/gif",
    "image/bmp",
  ];

  render() {
    const { close, downloadName, isPhone, show, title, type, url } = this.props;

    const fileType = show && this.getFileType(type, url);
    const downloadFile = isPhone || !fileType;

    return (
      <Modal
        show={show}
        className={`file-preview-modal${downloadFile ? " download-modal" : ""}`}
      >
        <div className="flex flex-justify-space flex-align-center mb-24">
          <p className="xl-text fw-500">{title}</p>
          <i
            className="material-icons pointer link-text-secondary"
            onClick={close}
          >
            close
          </i>
        </div>
        {downloadFile ? (
          <p className="line-height-double">
            File preview unavailable{" "}
            {isPhone ? "on mobile devices" : "for this file"}. Click{" "}
            <a className="link-text" download={downloadName || true} href={url}>
              here
            </a>{" "}
            to download your file.
          </p>
        ) : (
          <object data={url} type={fileType}>
            <embed src={url} type={fileType} />
          </object>
        )}
      </Modal>
    );
  }
}
