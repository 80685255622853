import React from "react";
import {
  getCurrencySymbol,
  formatNumber,
  pluralizeText,
} from "../../../../../lib";

export default class StudentRewardCard extends React.PureComponent {
  render() {
    const {
      rewardRequest: {
        creditsToRedeem,
        currencyCode,
        creditValue,
        rewardName,
        rewardType,
        rewardTypeDisplay,
        status,
      },
    } = this.props;

    return (
      <div
        className={`card reward-card ${
          rewardType && rewardType.toLowerCase()
        }-reward`}
      >
        <p className="uppercase-text xs-text fw-700 mb-8">
          {rewardTypeDisplay}
        </p>
        <p className="medium-text fw-700">{rewardName}</p>

        <div className="reward-card-footer mt-32 flex flex-align-center flex-justify-space">
          <p>
            {formatNumber(creditsToRedeem)}{" "}
            {pluralizeText("credit", creditsToRedeem)}{" "}
            {status === "Pending" ? "pending" : "used"}
          </p>
          {rewardType !== "Trip" && (
            <p className="accent-text">
              {getCurrencySymbol(currencyCode)}
              {formatNumber(creditValue * creditsToRedeem)} value
            </p>
          )}
        </div>
      </div>
    );
  }
}
