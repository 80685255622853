import React, { memo, useCallback, useEffect } from "react";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import Rewards from "../credits/components/myCredits/rewards/Rewards";
import { GrandTripFAQs } from "./GrandTripFAQsPage";

const GrandTripsPage = ({
  actions: { getRewards, getStudent, getStudentRewards, doRewardRequest },
  myJewishU,
  user,
  rewardRequest,
  rewards,
  student: { data: student },
  studentRewards,
  sys: { eduRewardTypes = [] },
}) => {
  const { data: { eduStudentEnrollmentID, eduChabadHouse } = {} } = myJewishU;

  const getStudentDetails = useCallback(async () => {
    if (eduStudentEnrollmentID) {
      await getStudent();
    }
  }, [eduStudentEnrollmentID, getStudent]);

  useEffect(() => {
    (async () => {
      if (!user) {
        await getRewards(true);
      } else {
        await getStudentDetails();
      }
    })();
  }, [getRewards, getStudentDetails, user]);

  const currencyCode = React.useMemo(() => {
    return user ? eduChabadHouse : "";
  }, [user, eduChabadHouse]);

  return (
    <React.Fragment>
      <div className="video-container">
        <video
          className="background-video"
          poster="/images/JU_Trips_Video.png"
          width="100%"
          height="360"
          controls
          playsInline
          autoPlay
          muted
          loop
        >
          <source
            src="https://cocistorage.blob.core.windows.net/prod/jewishu_public_site/JewishU%20Travel%20Promo.mp4"
            type="video/mp4"
          />
        </video>
        <div className="header-text">
          Exclusive Trips for Exceptional Learners
        </div>
      </div>
      <div className="my-credits-page">
        <Rewards
          getRewards={getRewards}
          getStudentRewards={getStudentRewards}
          refreshStudentDetails={() => getStudentDetails(true)}
          requestReward={doRewardRequest}
          rewardRequest={rewardRequest}
          rewards={rewards}
          rewardTypes={eduRewardTypes}
          student={student}
          studentRewards={studentRewards}
          currencyCode={currencyCode}
          isGrandTripPage={true}
          isLoggedIn={!!user}
        />
      </div>
      <GrandTripFAQs />
    </React.Fragment>
  );
};

export default memo(withLayout()(withAppInsights(GrandTripsPage)));
