import React from "react";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";

class About extends React.PureComponent {
  render() {
    return (
      <div className="page">
        <div className="container">
          <h1 className="about-page-title fw-700 accent-text-secondary text-center">
            A High-Quality Jewish Education to Last a Lifetime
          </h1>
          <h2 className="about-page-subtitle line-height-double text-center">
            JewishU is an academy that offers Jewish college students dozens of
            unique classes that demystify everything Jewish. From history to
            philosophy and everything in between, you’ll find that JewishU
            provides an accessible and engaging platform for a comprehensive
            Jewish education alongside your standard college education.
          </h2>
          <div className="about-jewish-u">
            <div className="mb-40 flex flex-align-center mobile-block">
              <img
                src="/images/about/welcoming-environment.jpg"
                alt="Warm and Welcoming Environment"
              />
              <div>
                <h3 className="xxl-text fw-600 mb-16">
                  Warm and Welcoming Environment
                </h3>
                <p className="large-text line-height-double">
                  In JewishU you’ll find a welcoming and relaxed learning
                  environment to begin or enhance your Jewish education. From
                  basic Jewish Literacy to Advanced Jewish Literature, JewishU
                  offers learning opportunities for every Jewish college
                  student.
                </p>
              </div>
            </div>
            <div className="mb-40 flex flex-align-center mobile-block">
              <img src="/images/about/local-courses.jpg" alt="Local Courses" />
              <div>
                <h3 className="xxl-text fw-600 mb-16">Local Courses</h3>
                <p className="large-text line-height-double">
                  All courses are presented locally, timeless Jewish knowledge
                  right around the corner.
                </p>
              </div>
            </div>
            <div className="mb-40 flex flex-align-center mobile-block">
              <img src="/images/about/subsidized.jpg" alt="100% Subsidized" />
              <div>
                <h3 className="xxl-text fw-600 mb-16">100% Subsidized</h3>
                <p className="large-text line-height-double">
                  Every Jew should be an educated Jew. That's why
                  philanthropists of diverse Jewish backgrounds teamed up with
                  Chabad on Campus International to make Jewish education
                  available to every Jewish student, with free enrollment,
                  rewarding credits, and an opportunity to earn a JewishU
                  Certificate in Judaic Studies.
                </p>
              </div>
            </div>
            <div className="mb-40 flex flex-align-center mobile-block">
              <img src="/images/about/credits.png" alt="JewishU Credits" />
              <div>
                <h3 className="xxl-text fw-600 mb-16">JewishU Credits</h3>
                <p className="large-text line-height-double">
                  JewishU credits have cash value that can be used either as
                  scholarships towards Jewish programs, charitable donations,
                  gift cards, or a grand trip. You learn, you earn, you decide.
                </p>
              </div>
            </div>

            <div className="flex flex-align-center mobile-block">
              <img
                src="/images/about/fits-busy-schedule.jpg"
                alt="Fits Busy Schedules"
              />
              <div>
                <h3 className="xxl-text fw-600 mb-16">Fits Busy Schedules</h3>
                <p className="large-text line-height-double">
                  Each course consists of just four classes and no homework.
                  Take one course or ten. You decide. Simply enroll once, then
                  easily manage courses, progress, and credits online.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="faqs">
          <div className="container">
            <h4 className="faq-title accent-text-secondary fw-700 mb-40">
              FAQs
            </h4>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                Who is JewishU for?
              </p>
              <p className="large-text line-height-double">
                Currently enrolled Jewish college students, between the ages of
                18-26.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                What does it cost to enroll in JewishU?
              </p>
              <p className="large-text line-height-double">
                JewishU courses are 100% subsidized. If you would like to help
                give young Jews the gift of a Jewish education, we welcome your
                partnership. Click{" "}
                <a
                  className="link-text"
                  href="https://chabadoncampus.org/donate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>{" "}
                to donate.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                How many courses do I need to take?
              </p>
              <p className="large-text line-height-double">
                As many as you want or your schedule allows.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                Where do JewishU classes take place?
              </p>
              <p className="large-text line-height-double">
                JewishU classes take place in person, locally, at a venue
                assigned by the local JewishU affiliate.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                Do I need to be Jewish?
              </p>
              <p className="large-text line-height-double">
                Yes, and that’s it. Whether you’re looking to begin or grow,
                JewishU has minicourses for all levels of Jewish knowledge.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                Do I need to know Hebrew?
              </p>
              <p className="large-text line-height-double">
                No. Most minicourses do not require Hebrew reading skills. The
                few that do will state it as a prerequisite.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                Do I need to be a current college student?
              </p>
              <p className="large-text line-height-double">
                Yes. JewishU is for Jewish college students ages 18-26.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                How do I receive JewishU credits and rewards?
              </p>
              <p className="large-text line-height-double">
                Upon completion of each JewishU minicourse, you will
                automatically receive JewishU credits. Credits can be managed
                and redeemed through your “Credits” page until their expiration,
                one year after your last JewishU class.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                Do JewishU credits roll over from year to year?
              </p>
              <p className="large-text line-height-double">
                JewishU credits remain active as long as you are taking JewishU
                minicourses. Credits expire twelve months after your last class.
              </p>
            </div>
            <div className="faq">
              <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
                How do I earn a JewishU Certificate in Judaic Studies?
              </p>
              <p className="large-text line-height-double">
                To earn a Certificate, you must take eight JewishU courses and
                write a ten-page thesis. Certificates are optional; you do not
                have to pursue a Certificate in order to study in JewishU.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLayout()(withAppInsights(About));
