import React from "react";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";
import LocationCourses from "./components/locationDetails/LocationCourses";
import LocationSidebar from "./components/locationDetails/LocationSidebar";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";

class LocationDetailsPage extends React.PureComponent {
  componentDidMount() {
    this.getLocationDetails();
  }

  componentWillUnmount() {
    this.props.actions.resetLocation();
  }

  getLocationDetails = async () => {
    const {
      pageRoute: {
        params: { locationId },
      },
    } = this.props;

    this.props.actions.getLocation(locationId);
  };

  getLocationCourses = (locationId, page, results, filters) => {
    this.props.actions.getLocationCourses(locationId, page, results, filters);
  };

  render() {
    const {
      courses,
      data: locationDetails,
      error,
      loading,
      myJewishU: { data: { eduChabadHouse: myLocation } = {} },
      pageRoute,
      sys: { eduCourseCategories = [] },
    } = this.props;
    const {
      chabadHouseName,
      defaultImageURL,
      defaultMessage,
      eduProgramImageURLOverride,
      eduProgramMessageOverride,
      id: locationId,
    } = locationDetails || {};

    return (
      <div className="page location-details-page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          !!locationId && (
            <div className="container">
              <div className="location-details-header">
                <div className="location-details-header-img-container">
                  <img
                    src={
                      eduProgramImageURLOverride ||
                      defaultImageURL ||
                      "/images/location-placeholder.jpg"
                    }
                    alt=""
                  />
                </div>
                <div className="container">
                  <p>
                    Explore your Jewish heritage. <br /> Easily. Deeply.
                    Rewardingly.
                  </p>
                </div>
              </div>
              <div className="container mb-80">
                <div className="flex mobile-block">
                  <div className="location-details-sidebar">
                    <LocationSidebar
                      isMyLocation={myLocation && myLocation.id === locationId}
                      location={locationDetails}
                    />
                  </div>
                  <div className="desktop-hidden tablet-hidden mobile-course-details-divider"></div>
                  <div className="full-width location-details-content">
                    <div className="location-details-section mb-80">
                      <p className="xxl-text fw-700 mb-16">
                        JewishU: {chabadHouseName}
                      </p>
                      <p
                        className="medium-text line-height-double"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {eduProgramMessageOverride || defaultMessage}
                      </p>
                    </div>
                    <LocationCourses
                      {...courses}
                      courseCategories={eduCourseCategories}
                      getCourses={(...passProps) =>
                        this.getLocationCourses(locationId, ...passProps)
                      }
                      locationId={locationId}
                      pageRoute={pageRoute}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

export default withLayout({
  // getTitle: ({ data: locationDetails }) =>
  //   locationDetails && locationDetails.chabadHouseName,
  getTitle: ({
    data: locationDetails,
    myJewishU: { data: { eduChabadHouse: myLocation } = {} },
  }) =>
    // page title is My Location at user location
    myLocation &&
    locationDetails &&
    myLocation.id === locationDetails.id &&
    "My Location",
  goBack: true,
})(withAppInsights(LocationDetailsPage));
